<template>
  <div>

    <footer
      class="musenzeArticle2"
      style="border: 1px solid #233A2E;"
    >
      <div class="container">
        <div
          class="foot-body"
          style="text-align: left;"
        >
          <h2 class="fsp">About Access Uganda Tours</h2>
          <p>
            Access Uganda Tours welcomes you to the true African wildlife and intriging cultural experiences.
            We let you step into the impenetrable ansd scenic African Jungle, embracing the quiet moments, watching the
            marveloues fauna without overlooking the mighty mountain gorillas whilst catching the breathtaking scenaries.
            <a routerLink="/about-us">More About Us</a>
          </p>
        </div>
      </div>
      <div
        id="footerdkt"
        class="container py-md-4 mt-md-3"
      >
        <div class="row footer-top-w3layouts-agile py-5">
          <div class="col-lg-3 col-md-6 col-sm-6 footer-grid">
            <div class="footer-title">
              <h3>Contact Us</h3>
            </div>
            <div class="footer-office-hour">
              <ul>
                <li>
                  <a href="https://wa.me/+256772455423/?text=Hello,AccessUgandaSafaris">
                    <i class="fab fa-whatsapp"></i> : +256 (0)772455423
                  </a>
                </li>
                <li>
                  <a href="tel:+256702455423">
                    <i class="fab fa-whatsapp"></i> :+256 (0)702455423
                  </a>
                </li>
                <li>
                  <a href="mailto:info@accessugandasafaris.com?subject=Hello, Access Uganda Safaris"> <i class="fas fa-envelope"></i> : info@accessugandasafaris.com</a>
                </li>
              </ul>
              <ul>
                <li><i class="fas fa-map-marker-alt"></i> : National Theatre Plot 2,4,6 Dewinton Rd</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 footer-grid">
            <div class="footer-title">
              <h3>Safaris by type</h3>
            </div>
            <div class="footer-office-hour">
              <ul>
                <li><a @click="$router.push({path: '/safari-packages'})">Luxury Safaris</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Gorilla Trekking</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Family Safaris</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Private Safaris</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Group Safaris</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 footer-grid">
            <div class="footer-title">
              <h3>Company</h3>
            </div>
            <div class="footer-office-hour">
              <ul>
                <li><a @click="$router.push({path: '/safari-packages'})">Tour Packages</a></li>
                <li><a @click="$router.push({path: '/experiences'})">Safari Experiences</a></li>
                <li><a @click="$router.push({path: '/about-us'})">About us</a></li>
                <li><a @click="$router.push({path: '/contact-us'})">Contact us</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 footer-grid">
            <div class="footer-title">
              <h3>General</h3>
            </div>
            <div class="footer-office-hour">
              <ul>
                <li><a @click="$router.push({path: '/terms-and-conditions'})">Terms and Conditions</a></li>
                <li><a @click="$router.push({path: '/tailor-made-safaris'})">Tailor Made Safaris</a></li>
                <li><a @click="$router.push({path: '/privacy-policy'})">Privacy policy</a></li>
                <!-- <li><a href="#">Partner Options</a></li> -->
                <li><a @click="$router.push({path: '/contact-us'})">Contact us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        id="footermbl"
        class=""
      >
        <el-collapse
          v-model="activeName"
          accordion
        >
          <el-collapse-item
            title="Contact Us"
            name="1"
          >
            <div
              class="footer-office-hour"
              style="text-align: left;"
            >
              <ul>
                <li>
                  <a href="https://wa.me/+256772455423/?text=Hello,AccessUgandaTours">
                    <i class="fab fa-whatsapp"></i> : +256 (0)772455423
                  </a>
                </li>
                <li>
                  <a href="tel:+256702455423">
                    <i class="fab fa-whatsapp"></i> : +256 (0)702455423
                  </a>
                </li>
                <li>
                  <a href="mailto:info@accessugandasafaris.com?subject=Hello, Access Uganda Tours"> <i class="fas fa-envelope"></i> : info@accessugandasafaris.com</a>
                </li>
              </ul>
              <ul>
                <li><i class="fas fa-map-marker-alt"></i> : National Theatre Plot 2,4,6 Dewinton</li>
              </ul>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Safaris by type"
            name="2"
          >
            <div
              class="footer-office-hour"
              style="text-align: left;"
            >
              <ul>
                <li><a @click="$router.push({path: '/safari-packages'})">Luxury Safaris</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Gorilla Trekking</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Family Safaris</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Private Safaris</a></li>
                <li><a @click="$router.push({path: '/safari-packages'})">Group Safaris</a></li>
              </ul>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Company"
            name="3"
          >
            <div
              class="footer-office-hour"
              style="text-align: left;"
            >
              <ul>
                <li><a @click="$router.push({path: '/safari-packages'})">Tour Packages</a></li>
                <li><a @click="$router.push({path: '/experiences'})">Safari Experiences</a></li>
                <li><a @click="$router.push({path: '/about-us'})">About us</a></li>
                <li><a @click="$router.push({path: '/contact-us'})">Contact us</a></li>
              </ul>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="General"
            name="4"
          >
            <div
              class="footer-office-hour"
              style="text-align: left;"
            >
              <ul>
                <li><a @click="$router.push({path: '/terms-and-conditions'})">Terms and Conditions</a></li>
                <li><a @click="$router.push({path: '/tailor-made-safaris'})">Tailor Made Safaris</a></li>
                <li><a @click="$router.push({path: '/privacy-policy'})">Privacy policy</a></li>
                <!-- <li><a href="#">Partner Options</a></li> -->
                <li><a @click="$router.push({path: '/contact-us'})">Contact us</a></li>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>

      </div>
    </footer>

    <!---->
    <div class="copyright py-3">
      <div class="container">
        <div class="copyrighttop">
          <ul>
            <li>
              <small>Follow us on:</small>
            </li>
            <li><a
                style="color: white;"
                href="https://www.facebook.com/accessuganda"
                target="_blank"
                title="Facebook"
              ><i class="fab fa-facebook-f"></i></a></li>
            <li><a
                style="color: white;"
                href=""
                target="_blank"
                title="Twitter"
              ><i class="fab fa-twitter"></i></a></li>
            <li><a
                style="color: white;"
                href=""
                target="_blank"
                title="Instagram"
              ><i class="fab fa-instagram"></i></a></li>
            <li><a
                style="color: white;"
                href=""
                target="_blank"
                title="Linkedin"
              ><i class="fab fa-linkedin"></i></a></li>
          </ul>
        </div>
        <div class="copyrightbottom">
          <p>
            <small>© 2022 Access Uganda Safaris Ltd . All Rights Reserved</small>
          </p>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>

    <section>
      <a
        href="https://wa.me/+256 702 455423/?text=Hello, Access Uganda Safaris"
        class="float"
        target="_blank"
      >
        <!--<i class="fa fa-whatsapp my-float"></i>-->
        <i class="fab fa-whatsapp my-float"></i>
      </a>
    </section>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ["1"],
      activeName: "1",
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
#footerdkt {
  display: block;
}
#footermbl {
  display: none;
}

.foot-body {
  padding-top: 30px;
}

.musenzeArticle2 {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(16, 50, 48, 0), rgba(16, 50, 48, 0.9)),
    url("../assets/images/IMG_0046.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #233a2e;
  /* padding: 50px 50px 50px 50px; */
  color: rgb(218, 216, 216);
}

footer {
  background-color: #233a2e;
}
footer h3 {
  font-size: 1.2em;
  color: #ffffff;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
footer p {
  color: #bfbfbf;
  font-size: 15px;
  line-height: 1.8em;
}
footer ul li {
  display: block;
  color: #bfbfbf;
  font-weight: 300;
  font-size: 13px;
  /* font-size: 15px; */
  /* line-height: 1.8em; */
  /* margin: 0.7em 0; */
}
.footer-list i {
  padding-right: 10px;
  color: #ffa41f;
}
footer ul li a {
  color: #bfbfbf;
  font-weight: 300;
  display: block;
  padding: 5px 0px;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
footer ul li a:hover {
  color: green;
}
footer ul li:hover {
  color: green;
}

.newsletter .email {
  background-color: #f4f4f4;
  border: none;
}
.newsletter .email {
  outline: none;
  padding: 13px 15px;
  color: #fff;
  font-size: 14px;
  width: 80%;
  background: rgba(0, 0, 0, 0.22);
  border: 1px solid #2d2d2d;
}
.newsletter {
  position: relative;
  margin-top: 2em;
}
button.btn1 {
  color: #fff;
  border: none;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  background: #eece19;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  float: right;
  cursor: pointer;
  width: 20%;
}
/*--//footer--*/

/*--/copyright--*/
.copyright ul li {
  display: inline-block;
  padding: 0 10px;
}
a.facebook {
  color: #fff;
  font-size: 16px;
}
a.facebook:hover {
  color: #0048ff;
}
.copyrighttop {
  float: right;
}
.copyrightbottom {
  float: left;
}
.copyright {
  background: #233a2e;
  color: #fff;
  border-top: 1px solid #233a2e;
}
.copyrightbottom p {
  color: #fff;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 0;
}
.copyrightbottom a {
  color: #0048ff;
  text-decoration: none;
}
.copyrightbottom a:hover {
  color: #fff;
}
.copyrighttop h4 {
  font-size: 0.95em;
}
/*-- //what we do --*/

.w3_agileits_header.two {
  color: #fff;
}
/*-- //services --*/

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}

/*##Device = Laptops, Desktops##Screen = B/w 1025px to 1280px*/

@media (min-width: 1281px) {
}

/*##Device = Laptops, Desktops##Screen = B/w 1025px to 1280px*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  #menucontainer {
    display: block;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
  }
  #footerdkt {
    display: block;
  }
  #footermbl {
    display: none;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #menucontainer {
    display: block;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
  }
  #footerdkt {
    display: block;
  }
  #footermbl {
    display: none;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  h2 {
    font-size: 1.2em;
  }
  #menucontainer {
    display: block;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
  }
  #footerdkt {
    display: none;
  }
  #footermbl {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  h2 {
    font-size: 1.2em;
  }
  .foot-body {
    padding-top: 30px;
  }
  #navspace {
    /* padding-left: 15px; */
    width: 100%;
    padding: 0px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
  }
  #logoImg {
    width: 66.666666667px;
    height: 44.666666667px;
  }

  .footer-logo,
  .ftls {
    display: none;
  }

  .footer-office-hour {
    padding-bottom: 10px;
  }
  #footerdkt {
    display: none;
  }
  #footermbl {
    display: block;
  }

  .copyrighttop {
    float: none;
  }
  .copyrightbottom {
    float: none;
  }
  .copyright {
    text-align: center;
    color: grey;
  }
}
/** MEDIA ENDS HERE***/
</style>

<style>
footer .el-collapse-item__content {
  background-color: #233a2e !important;
}
footer .el-collapse-item {
  background-color: transparent !important;
}
footer .el-collapse-item__header {
  background-color: transparent;
  color: white;
}
footer .el-collapse-item__body {
  background-color: transparent;
  color: white;
}
footer .el-collapse-item__header {
  padding: 15px;
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #2c6b4b !important;
  font-size: 13px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
}
footer .el-collapse {
  border-top: 1px solid #2c6b4b;
  border-bottom: 1px solid #2c6b4b;
}
</style>